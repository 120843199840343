<template>
<div>
    <v-container class="px-0">
        <v-row>
            <v-col cols="12">
                <s-toolbar :color="'#244093'" dark label="Consulta de Clientes EVOLTA" class="tran"></s-toolbar>
                <v-card>

                    <v-row style="margin-top: -40px;">

                        <v-col cols="12">
                            <v-card class="mx-auto my-1" width="504">
                                <v-row justify="center" style="margin-top: 4px; margin-left: 10px; margin: auto">
                                    <v-col cols="12" lg="12" md="12">
                                        <s-select-definition def="1130" v-model="txtCuenta" label="Seleccione una cuenta"></s-select-definition>
                                    </v-col>
                                     <v-col cols="8" lg="8" md="8">
                                        <s-import-excel></s-import-excel>
                                    </v-col>
                                    <v-col cols="4" lg="4" md="4">
                                        <s-date></s-date>
                                    </v-col>
                                </v-row>
                            
                                <v-divider class="mx-4"></v-divider>

                                <v-card-actions>
                                    <!-- <v-btn color="info" small rounded>
                                        Migrar Solo Cliente
                                    </v-btn>
                                     <v-btn color="info" small rounded>
                                        Migrar Solo Lote
                                    </v-btn> -->
                                     <v-btn color="info" small rounded @click="btnMigrate()">
                                        Generar Creep
                                    </v-btn>
                                   
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12">
                            <v-data-table dense :headers="headers" :items="item" :items-per-page="-1" hide-default-footer>

                                <!-- <template v-slot:item.action="{ item }">
                                    <v-btn color="warning" @click="btnMigrate(item)" x-small>
                                        Migrar
                                    </v-btn>
                                </template> -->
                            </v-data-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="processing" :persistent="messageProcessing.length == 0" width="400">
            <v-card color="primary" dark>
                <v-card-text v-if="messageProcessing.length == 0">
                    Por favor espere
                    <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
                </v-card-text>
                <v-card-text v-else>
                    <div class="text--white pt-4">
                        Error al realizar busqueda <br />
                        {{ messageProcessing }}
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

    </v-container>
</div>
</template>

<script>
import _sCollection from "@/services/Collection/CobSearcCustomerService.js"
import SImportExcel from '../../components/Utils/SImportExcel.vue';
export default {
    components: {SImportExcel},
    data() {
     
           return {
            processing: false,
            messageProcessing: "",
            txtDocIdentidad: "",
            item: [],
            itemOne: {
                CobDraft: "",
                CobStage: "",
                CobNumberInmueble: "",
                CobTypeDocument: "",
                CobCustomerDocument: "",
                CobCustomnerName: "",
                CobCustomerAddress: "",
                CobCustomerEmail: "",
                CobCustomerTelephone: "",
                CobCustomerProvince: "",
                CobCustomerDistrict: "",
                CobCustomerNacionality: "",
                CobNumberSale: "",
            },
            headers: [
               /*  {
                    text: "action",
                    value: "action",
                    width: 50
                }, */
                {
                    text: "Proyecto",
                    value: "CobDraft",
                    width: 200
                },
                {
                    text: "Etapa",
                    value: "CobStage",
                    width: 100
                },
                {
                    text: "Nro. Inmueble",
                    value: "CobNumberInmueble",
                    width: 100
                },
                {
                    text: "Tipo Doc.",
                    value: "CobTypeDocument",
                    width: 100
                },
                {
                    text: "Nro. Dni",
                    value: "CobCustomerDocument",
                    width: 100
                },
                {
                    text: "Nombre Titular",
                    value: "CobCustomnerName",
                    width: 200
                },
                {
                    text: "Dirección",
                    value: "CobCustomerAddress",
                    width: 200
                },
                {
                    text: "Email",
                    value: "CobCustomerEmail",
                    width: 100
                },
                {
                    text: "Celular",
                    value: "CobCustomerTelephone",
                    width: 100
                },
                {
                    text: "Provincia",
                    value: "CobCustomerProvince",
                    width: 100
                },
                {
                    text: "Distrito",
                    value: "CobCustomerDistrict",
                    width: 100
                },
                {
                    text: "Nacionalidad",
                    value: "CobCustomerNacionality",
                    width: 100
                },
                {
                    text: "N° de Venta",
                    value: "CobNumberSale",
                    width: 100
                },
            ]
        };
    },

    created() {},

    watch: {},

    methods: {

        btnSearch() {

            if (this.txtDocIdentidad.length == 0 || this.txtDocIdentidad == "" || this.txtDocIdentidad == null) {
                this.$fun.alert("Ingrese Documento de Identidad", "warning")
                return;
            }

            if (this.txtDocIdentidad.length < 8) {
                this.$fun.alert("Documento Invalido", "warning")
                return;
            }

            this.processing = true;
            _sCollection.searchCustomer(this.$fun.getUserID(), this.txtDocIdentidad).then(r => {
                    if (r.status == 200) {
                        this.processing = false;
                        this.item = r.data.filter(x => x.CobCustomerDocument == this.txtDocIdentidad);

                        if (this.item.length > 0) {
                            this.itemOne = this.item[0]
                        }
                        /* console.log(this.item) */
                    }

                },
                (e) => {
                    this.messageProcessing = e.response.data.Message;
                    this.processing = true;
                });
        },

        btnMigrate() {
           /*  console.log("Guardar", this.itemOne) */

            this.$fun.alert("¿Seguro de Migrar el registro seleccionado al Sistema de Cobranzas?", "question").then(x => {
                if (x.value) {
                    _sCollection.save(this.itemOne, this.$fun.getUserID()).then(resp => {
                        if (resp.status == 200) {
                            this.$fun.alert("Migrado Correctamente", "success")
                            this.itemOne = {}
                        }
                    })
                }
            })
        }
    },
};
</script>

<style>
.tran {
    opacity: 0.7 !important;
}
</style>
